/* ==========================================================================
	Global
	========================================================================== */

*::selection {
	background-color: #B3D4FB;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	text-shadow: 0 1px 0 black;
	color:$brand-primary !important;

	small{
		display: block;
	}
}

.page-header-top {
	@extend .container-max;
	margin: 6rem auto;
	position: absolute;
	z-index: 10;
	bottom: 100%;
	left: 0;
	right: 0;
	
	>h1 {
		font-size: 5.5rem;
		margin: 0;
		color: white;
	}
}

.btn, a, button {
	outline: 0 !important;
	transition: all linear 0.3s;
}

a.thumbnail:hover {
	border-color: #222 !important;
}

.container-max {
	@extend .container-fluid;
	@extend .center-block;

	max-width: 1440px;
	

}

.container-main {
	@extend .container-max;
	// padding-bottom: 6rem;
	// padding-top: 3rem;
	position: relative;
}

.container-blog {
	@extend .container-main;

	h2, h3, h4, h6 {
		font-family: $font-family-base;
	}

	h2, h3 {
		font-weight: 300;
	}
}

.no-pad {
	padding: 0;
}

/* ==========================================================================
	Headers
	========================================================================== */

@import "header-basic";
// @import "header-basic-2";
// @import "header-slider-1";
// @import "header-slider-2";
// @import "header-slider-3";
// @import "header-slider-4";
// @import "header-slider-5";


/* ==========================================================================
	Main
	========================================================================== */

.main {
	@extend .clearfix;
	min-height: 70rem;
	position: relative;
	z-index: 0;
	overflow:hidden;
}


/* ==========================================================================
	Footer
	========================================================================== */

.footer {
	background-color: $body-bg;
	border-top: solid 1px $gray-dark;
}

.container-footer {
	@extend .container-max;
	padding: 6rem;

	@media (max-width: $screen-xs-max) {
		text-align: center;
	}
}

.footer-main {
	@extend .clearfix;
}

ul.list-footer {
	@extend .list-unstyled;
	margin: 0;
	line-height: 2.3rem;

	>li {
		padding: .3rem 0;
	}

	@media (max-width: $screen-xs-max) {
		padding-top: 20px;
		margin-top: 20px;
		border-top: solid 1px rgba(black, .2);
	}
}

ul.list-footer-right {
	@extend .list-unstyled;
	margin: 0;
}

ul.list-logo-footer {
	@extend .list-inline;
	padding-top: 3rem;
	margin-top: 3rem;
	border-top: solid 1px $gray-dark;

	>li {
		padding: 0;
	}

	@media (max-width: $screen-sm-max) {
		text-align: center;
		padding-top: 20px;
		margin-top: 20px;
		border-top: solid 1px rgba(black, .2);
	}

	img {
		height: 37px;
	}
}

.panel-ref-footer {
	@extend .well;
}

/* ==========================================================================
	Custom
	========================================================================== */

.row-flex-pict {
	@extend .row;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;

	img {
		display: inline-block;
		height: 15rem;
		margin:  1rem;
	}
}

.row-flex {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}

.row-flex-custom {
	@extend .row-flex;
	margin-top: -8rem;
}

.panel-flex {
	margin: $grid-gutter-width/2;
	padding: 2rem;
	max-width:30%;
	background-color: $gray-dark;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	position: relative;
//   box-shadow: 0 0 25px rgba($brand-primary, 0.2);
		border-radius:5px;

	&::before {
		content: '\f02e';
		font-family: 'fontAwesome';
		font-size: 2.5rem;
		line-height: 1;
		position: absolute;
		top: -5px;
		left: 3rem;
		color: $brand-primary;
		text-shadow: 0 5px 10px black;
	}

	>p {
		margin: auto;
	}

	@media(min-width: $screen-sm-min) {
		padding: 5rem;
	}
}

.panel-flex-item {
	margin: $grid-gutter-width/2;
	padding: 2rem;
	max-width: 50%;
	background-color: $gray-dark;
	position: relative;
	  box-shadow: 0 0 25px rgba(0, 0, 0, 0.4);

		border-radius:5px;
	
	@media(min-width: $screen-sm-min) {
		padding: 5rem;
	}

	>h2 {
		font-size: $font-size-h3;
		margin: 0 0 2rem;
	}

	address {
		line-height: 1.8;
		margin: 0;
	}

	em {
		font-size: $font-size-small;
	}
}

.index-presentation {
	margin: 3rem auto;
	max-width: 100rem;
}

.panel-lead {
	padding: 3rem;
	font-size: $font-size-large;
	border-bottom: solid 2px $gray-dark;
}

.page-header-second {
	@extend .text-center;
	margin: 3rem 0 6rem;
	padding-bottom: 3rem;
	position: relative;
	text-shadow: 0 1px 0 rgba(black, .8);

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 8rem;
		height: 4px;
		margin-left: -4rem;
		background-color: $brand-primary;
		// box-shadow: 0 1px 0 rgba(black, .8);
	}
}

.section-top {
	padding: 3rem 0 6rem;
	background: url(../images/charte/section-1.jpg) no-repeat center fixed;
	background-size: cover;
}

.content-section {
	position: relative;
	z-index: 1;
}

.img-center {
	@extend .text-center;
	
	>img {
		display: inline-block;
		max-width: 100%;
		margin: 0 auto;
		display: inline-block;
		border: solid 10px white;
		box-shadow: 0 1rem 5rem rgba(black, .5);
	}
}

.section-actu {
	padding: 3rem 0;

	.fiche-popup{
			border-radius:5px;	
			border:solid 1px $brand-primary;
	}
}

.section-timeline {
	padding: 6rem 0;
	// background-color: white;
}

.content-timeline {
	width: 140rem;
	margin: 3rem auto;
	max-width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		z-index: 0;
		top: -6rem;
		left: 50%;
		bottom: 3rem;
		width: 3px;
		margin-left: -1px;
		background-color: $brand-primary;
		// box-shadow: 0 0 2rem $brand-primary;
	}
}

.panel-timeline {
	margin: 3rem 0;
	position: relative;
	z-index: 1;
	display: flex;
	flex-flow: row wrap;
	overflow: hidden;
	max-width: 100vw;

	&:nth-child(2n) {
		justify-content: flex-end;

		.panel-timeline__figure {
			order: 2;
		}

		.panel-timeline__block {
			order: 1;
		}
	}
}

.panel-timeline__wrap {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	
	@media(min-width: $screen-md-min) {
		width: 90%;
	}
}

.panel-timeline__figure {
	width: 100%;

	>img {
		@extend .img-responsive;
	}

	@media(min-width: $screen-md-min) {
		width: 35%;
	}
}

.panel-timeline__block {
	@extend .flexcenter-2;
	width: 100%;
	padding: 2rem;
	background-color: rgba($gray-darker, 1);
	  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);

	>h3 {
		margin: 0 0 1rem;
	}
	
	@media(min-width: $screen-md-min) {
		width: 65%;
		padding: 5rem;
	}
}
.radius-d{
	border-radius:0 5px 5px 0;
}

.radius-g{
	border-radius: 5px 0 0 5px;
}

.section-map {
	padding-bottom: 6rem;
}

.embed-responsive-map {
	padding-bottom: 60rem;
}

.embed-responsive-map2 {
	padding-bottom: 30rem;
}

.section-visite-virtuelle {
	@extend .text-center;
	padding: 6rem 0;
	border-top: solid 1px $gray-dark;
	border-bottom: solid 1px $gray-dark;
}

.panel-visite-virtuelle {
	padding: 3rem 0;
	border-bottom: solid 1px $gray-dark;

	&:last-child {
		padding-bottom: 0;
		border: none;
	}

	>.h3 {
		margin: 0 0 1rem;
	}
}

.form-control, select {

    width: 100%;
    height: 46px;
    padding: 10px 15px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #fff;
    border-radius: 0;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    font-size: 16px;
    line-height: 1.42857;
    color: #000;
    display: block;

}
///////////////////////////////////
///////////////////////////////////
///////////////////////////////////
///////////////////////////////////
///////////////////////////////////


.flexbase {
	display: flex;
	//flex-direction: column;


	@media (max-width:$screen-xs-min) {
		flex-direction: column;
	}

	@media (max-width:$screen-sm-min) {
		flex-direction: column;
	}
}

.flexcenter-2 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.ta {
	@extend .text-left;
	margin: 0 0 20px 0;
	padding: 0 0 25px 0;
	position: relative;
	color: $brand-primary;
	text-transform: uppercase;
	line-height: 1.5;

	small {
		line-height: 1.5
	}

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 4rem;
		height: 4px;
		background-color: $brand-primary;
	}
}

.ta-inverse {
	@extend .text-left;
	@extend .ta;
	color: white !important;

	&::after {
		background-color: white;
	}

}

.bg-white{
	background:white !important;
}

.smap {
  
	  .map {
		  padding-bottom: 500px;
	  }
  
	  .block1 {
		  padding: 3%;
		 color: white;
		  background: $brand-primary;
	  }
  }


  .figure5{
	background: url(../images/presentation/musee5.jpg) no-repeat center;
	background-size: cover;
	min-height:50vh;
  }

  .figure4{
	background: url(../images/presentation/musee4.jpg) no-repeat center;
	background-size: cover;
	min-height:50vh;
  }

  .figure3{
	background: url(../images/presentation/musee3.jpg) no-repeat center;
	background-size: cover;
	min-height:50vh;
  }

  .figure2{
	background: url(../images/presentation/musee2.jpg) no-repeat center;
	background-size: cover;
	min-height:50vh;
  }


  .dropdown-base ul.nav-base li.list-group-item a {
    font-size: 1.2rem !important;
}