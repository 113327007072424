/* ==========================================================================
	 header-basic
	 ========================================================================== */

/* ==========================================================================
	header-basic
	========================================================================== */

.header-basic {
	height: 579px;
	background: url(../images/charte/header-bg.jpg) no-repeat center top fixed;
	border-bottom: solid 1rem $brand-primary;
	position: relative;
	z-index: 0;

	&::after {
		content: '';
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(to bottom, rgba(black, 0) 50%, rgba(black, .5) 100%);
	}
}

.navbar-header-top {
	position: fixed;
	z-index: 200;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	height: auto;
	transition: all ease-in-out .3s;

	@media(min-width: $screen-lg-min) {
		flex-direction: row;
		height: 10rem;
		background-color: rgba($gray-darker, .8);
	}

	&.affix {
		height: 6rem;
		background-color: $gray-darker;
	}
}

.navbar-header-top__logo-header {
	@extend .text-center;
	width: 100%;
	position: relative;
	background-color: rgba(black, .8);

	@media(min-width: $screen-lg-min) {
		width: 20%;
		background-color: transparent;
	}

	>.wrap-logo-header {
		display: block;
		width: 220px;
		margin: auto;
		// height: 30rem;
		background-color: $brand-primary;
		overflow: hidden;
		transition: all ease-in-out .3s;

		>img {
			@extend .img-responsive;
			display: inline-block;
			// height: 30rem;
			transition: all ease-in-out .3s;
		}
	}
}

.navbar-header-top.affix {

	.wrap-logo-header {
		height: 6rem;
		background-color: $brand-primary;

		>img {
			margin-top: -1.6rem;
			height: 15rem;
		}
	}
}


/* ==========================================================================
	Navbar
	========================================================================== */

.wrap-navbar {
	width: 100%;

	@media(min-width: $screen-lg-min) {
		width: 80%;
		display: flex;
		align-items: center;
	}
}

.navbar {
	width: 100%;
	background-color: rgba(black, .8);

	@media(min-width: $screen-lg-min) {
		background-color: transparent;
	}
}

.navbar-nav {
	font-family: $headings-font-family;
	font-size: 1.5rem;


	>li {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			right: 50%;
			height: 0;
			background-color: $brand-primary;
			opacity: 0;
			transition: all ease-in-out .3s;
		}

		&:hover::after,
		&:active::after,
		&:focus::after,
		&.active::after {
			left: 1rem;
			right: 1rem;
			height: 3px;
			opacity: 1;
		}
	}
}

.index-nav {
	@extend .pull-right;
	display: inline-block;
	line-height: $navbar-height;
	padding-right: 10px;
	color: $navbar-default-toggle-icon-bar-bg;
	text-transform: uppercase;
	font-weight: 700;
	
	@media (min-width: $grid-float-breakpoint) {
		display: none;
	}
}